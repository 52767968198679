<template>
  <div class="container">
    <div class="pre-body pre-body-sm">
      <img
        class="pre-landing--logo"
        src="/assets/images/logoGlitch.gif"
        alt=""
      />

      <div class="pre-headline centered">
        Welcome to the Paranormal Institute
      </div>

      <div class="pre-divider"></div>

      <div class="pre-copy centered">
        <p>
          Are you a curious and brave individual seeking a challenging new line
          of work? Do you believe in paranormal phenomena? Have you ever had an
          encounter you just can not explain? Are you looking to make a
          difference?
        </p>
      </div>

      <div class="pre-subhead centered">Then we want YOU!</div>

      <div class="pre-copy centered">
        <p>
          The Paranormal Institute is recruiting interested individuals to take
          part in their new Ghostbusters Training Program. Learn from the
          leaders of Professional Paranormal Investigations and Eliminations and
          become a Ghostbuster.
        </p>
      </div>

      <div class="pre-pad"></div>

      <div class="pre-btns centered">
        <img
          class="pre-btn--lights"
          src="/assets/images/knightrider.png"
          alt=""
        />
        <pnw-button
          variant="primary"
          size="xl"
          @click="gotoRegister"
          v-if="!isAuthenticated"
        >
          Register Now
        </pnw-button>
        <pnw-button
          variant="primary"
          size="xl"
          @click="gotoProfile"
          v-if="isAuthenticated"
        >
          Go to Profile
        </pnw-button>
        <img
          class="pre-btn--lights flipped"
          src="/assets/images/knightrider.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PnwButton from "../components/PnwButton.vue";
import routerItems from "../constants/routerItems";
export default {
  components: { PnwButton },
  name: routerItems.LANDING,
  methods: {
    gotoRegister() {
      this.$router.push({ name: routerItems.REGISTER });
    },
    gotoProfile() {
      this.$router.push({ name: routerItems.PROFILE });
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/colors";
.pre-intro--logo > img {
  width: 100%;
}
.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
</style>
